$background: transparent;
$noir: black;
$viewer: transparent;
$white: white;
$margin: 60px;
$margin-mobile: 30px;
#content {
  height: 100%;
  @media screen and (max-width: 800px) and (orientation: portrait) {
    width: calc(100% - $margin-mobile - 0px);
  }
  #mainGallery {
    height: 100%;
    .mainColumn {
      display: flex;
      height: 100%;
      flex-direction: column;
      .horizontal_viewer {
        position: relative;

        // grid-template-columns: 1fr 1fr 1fr;
        // grid-gap: 10px;
        // margin: 10px;
        margin-top: 10px;
        // display: grid;
        background-color: $viewer;
        // padding: 8px;
        border-radius: 8px;

        .handle {
          position: absolute;
          z-index: 999;
          border-radius: 50%;
          padding: 5px;
          background-color: $white;
          cursor: pointer;
          user-select: none;
          top: -5px;
          left: -5px;
        }
        &.text {
          grid-template-columns: 1fr;
          background-color: $background;
          margin-bottom: 40px;
          @media screen and (max-width: 800px) and (orientation: portrait) {
            margin-bottom: 20px;
          }
          p {
            font-size: 5vw;
            font-family: "Averta W01 Bold Italic", sans-serif;
            line-height: 5vw;
          }
          &.regularText {
            display: flex;
            &.right {
              flex-direction: row-reverse;
            }

            p {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 1.5vw;
              font-family: "Averta W01 Regular", sans-serif;
              line-height: 1.5vw;
              max-width: 50%;
              min-width: 600px;
              column-gap: 10px;
              &.ql-align-right {
                text-align: right;
              }
              span {
                &.ql-align-right {
                  text-align: right;
                }
                &.ql-size-large {
                  font-size: 3vw;
                  line-height: 3vw;
                }
                &.ql-size-small {
                  font-size: 0.9vw;
                  line-height: 0.7vw;
                }
              }
              @media screen and (max-width: 800px) and (orientation: portrait) {
                width: calc(100% - 15px);
                max-width: calc(100% - 15px);
                min-width: calc(100% - 15px);
                column-count: 1 !important;
                font-size: 3.5vw;
                line-height: 3.5vw;
                &.ql-align-right {
                  text-align: unset;
                }
                span {
                  &.ql-align-right {
                    text-align: unset;
                  }
                  &.ql-size-large {
                    font-size: 5vw;
                    line-height: 5vw;
                  }
                  &.ql-size-small {
                    font-size: 2.5vw;
                    line-height: 2.5vw;
                  }
                }
              }
            }

            &.fullwidth {
              p {
                width: 100%;
                max-width: unset;
                min-width: unset;
              }
            }
          }
        }
        .organizer {
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 10px;
          margin: 10px;
          margin-top: 0px;
          // margin-top: 0px;
          display: grid;
          .image {
            // aspect-ratio: 1/1;
            aspect-ratio: 0.666667 / 1;
            display: block;
            position: relative;
            background-color: $background;

            &.doubleColumn {
              grid-column: span 2;
            }
            &.tripleColumn {
              grid-column: span 3;
            }
            .ratioBox {
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: cover;
              width: 100%;
              height: 100%;
              position: relative;
              video {
                width: 100%;
                height: auto;
                display: block;
                // border-radius: 12px;
              }
              .download-button {
                position: absolute;
                bottom: 0px;
                right: 0px;
                // background-color: rgba(255, 255, 255, 0.5);
                color: transparent;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @media screen and (max-width: 800px) and (orientation: portrait) {
                  display: none;
                }
              }
              &:hover {
                .download-button {
                  // background-color: rgba(255, 255, 255, 1);
                  // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                  color: white;
                }
              }
            }
            .select-wrapper {
              position: relative;
              height: 20px;
              select {
                background-color: white;
                border: unset;
                padding: unset;
                border-radius: unset;
                color: unset;
                font-size: unset;
                width: 100%;
                position: absolute;
              }
            }
            ul {
              position: relative;
              bottom: 0px;
              list-style: none;
              display: flex;
              flex-wrap: wrap;
              &::before {
                padding-top: 0px;
                font-size: 1.5em;
                content: "●";
              }

              li {
                padding: 0px;
                margin: 0px;
                margin: 5px;
                margin-right: 0px;
                padding-top: 5px;
                font-size: 1em;
                font-weight: 300;
                text-transform: uppercase;
                text-decoration: underline;
                cursor: pointer;
                &:not(:first-child) {
                  &::before {
                    content: "/ ";
                  }
                }
                &:hover {
                  color: $noir;
                }
                background-color: unset;
                color: #555;
                font-size: 0.7rem;
              }
            }

            .overlay {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0px;
              text-align: right;
              .edit-button,
              .delete-button {
                position: absolute;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                bottom: 30px;
                right: 2px;
                border: none;
                background-color: rgba(255, 255, 255, 0.5);
                color: #555;
                cursor: pointer;
                &:hover {
                  background-color: rgba(255, 255, 255, 1);
                  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                }
              }
              .delete-button {
                left: 2px;
                &:hover {
                  background-color: rgba(255, 0, 0, 1);
                }
              }
              .size-button {
                display: inline-flex;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin: 2px;
                background-color: rgba(255, 255, 255, 0.5);
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                  background-color: rgba(255, 255, 255, 1);
                  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                }
                &.selected {
                  background-color: rgba(255, 255, 255, 1);
                  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
        }

        .text-editor {
          padding: 10px;
        }
      }
      @media screen and (max-width: 800px) and (orientation: portrait) {
        .horizontal_viewer {
          p {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
