@font-face {
  font-family: "Averta W01 Black Italic";
  src: url("./Fonts/2999403/ea8c6a50-72d0-4ee6-8e0b-e47e53575d67.woff2")
      format("woff2"),
    url("./Fonts/2999403/f31b446c-483f-4030-8d67-e0d38b0b6fa4.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Bold Italic";
  src: url("./Fonts/2999405/5e5cbca9-4efe-45b6-a287-96962556b612.woff2")
      format("woff2"),
    url("./Fonts/2999405/d888bc61-de40-4e37-9400-0d82440a8e98.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Thin Italic";
  src: url("./Fonts/2999409/aa6e7c45-ff1a-4bdc-894b-46e20e759a18.woff2")
      format("woff2"),
    url("./Fonts/2999409/8c6efa3c-b0b0-409b-83b9-7c127c7f0c13.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 ExtraBold";
  src: url("./Fonts/2999415/d1b10202-af0e-46d9-8037-e64031a1c01f.woff2")
      format("woff2"),
    url("./Fonts/2999415/10f8e758-7c4c-47ee-b3f5-6d403716df27.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Black";
  src: url("./Fonts/2999417/4e32fe9a-3c73-4a05-b996-73380fe0d650.woff2")
      format("woff2"),
    url("./Fonts/2999417/355b4871-d3ef-47a0-81c2-c01f3308822c.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Regular";
  src: url("./Fonts/2999421/89f1e0ce-0337-46bd-83eb-521970fb47c9.woff2")
      format("woff2"),
    url("./Fonts/2999421/ffec1ad2-fea3-4d14-bc1f-bc1b0396417b.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Bold";
  src: url("./Fonts/2999427/c52b7ee6-2bc8-4dfc-8fef-36af3c2d0f63.woff2")
      format("woff2"),
    url("./Fonts/2999427/51d44388-ec35-4d35-a644-48812f2b3f67.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Thin";
  src: url("./Fonts/2999429/53e2bf47-af75-465c-856b-818ebb84b427.woff2")
      format("woff2"),
    url("./Fonts/2999429/e1c93287-d8c4-42db-b433-a1e9fe3295d1.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Semibold Italic";
  src: url("./Fonts/2999431/f2e079f4-7f94-4e35-ac26-3fbc57d6c267.woff2")
      format("woff2"),
    url("./Fonts/2999431/b38261c5-b66b-450d-a3ea-1fc312007f64.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Semibold";
  src: url("./Fonts/2999437/1e92f6d7-7f6f-4498-8aef-b4ae8457137f.woff2")
      format("woff2"),
    url("./Fonts/2999437/1f092190-9543-465b-803c-957470fe5c40.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 ExtraBold Italic";
  src: url("./Fonts/2999445/f4534f76-7894-4050-b4f9-168de09303b6.woff2")
      format("woff2"),
    url("./Fonts/2999445/b388df60-03bc-4830-a454-a9a7a42b96a7.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Extrathin Italic";
  src: url("./Fonts/2999447/b500bff5-dcf3-43dc-ae16-600bc1b20438.woff2")
      format("woff2"),
    url("./Fonts/2999447/cd1b768c-7d83-4ec6-a646-6cbfbdbabbf2.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Extrathin";
  src: url("./Fonts/2999449/e4532f93-feab-4dd6-877e-59b3a2ac085c.woff2")
      format("woff2"),
    url("./Fonts/2999449/94126aee-f5cb-407e-b0b9-928300c8b27f.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Light";
  src: url("./Fonts/2999455/1072dd23-6dea-428e-bd0f-a00a69074239.woff2")
      format("woff2"),
    url("./Fonts/2999455/53101a83-dea0-4ac0-8d40-12cf0cbe2956.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Light Italic";
  src: url("./Fonts/2999463/bd2c76e8-7ea6-4e7a-a6dd-94c9200909b4.woff2")
      format("woff2"),
    url("./Fonts/2999463/f99fc351-1e53-4330-b268-6e870f3ce772.woff")
      format("woff");
}
@font-face {
  font-family: "Averta W01 Regular Italic";
  src: url("./Fonts/2999465/fac93f55-a52e-4691-a5f3-fc89270fe480.woff2")
      format("woff2"),
    url("./Fonts/2999465/436a67bd-97e2-4d02-83ac-07a7a7894609.woff")
      format("woff");
}
