$background: white;
$noir: black;
$margin: 60px;
$margin-mobile: 25px;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Averta W01 Regular", sans-serif;
  min-height: 100vh;
  color: #555;
  margin: 0px;
  padding: 20px;
  font-weight: 100;
  padding-right: $margin;
  padding-left: $margin;
  @media screen and (max-width: 800px) and (orientation: portrait) {
    // grid-template-columns: 1fr;
    padding: 0px;
    padding-top: 0px;
  }
  // #content {
  //   height: 100%;
  //   #mainGallery {
  //     height: 100%;
  //     .mainColumn {
  //       display: flex;
  //       height: 100%;
  //       flex-direction: column;
  //       .horizontal_viewer {
  //         grid-template-columns: 1fr 1fr 1fr;
  //         grid-gap: 10px;
  //         margin: 10px;
  //         margin-top: 0px;
  //         display: grid;
  //         &.text {
  //           grid-template-columns: 1fr;
  //           background-color: $background;
  //           p {
  //             font-size: 4vw;
  //             font-family: "Averta W01 Bold Italic", sans-serif;
  //             line-height: 3.8vw;
  //           }
  //         }
  //         .image {
  //           // aspect-ratio: 1/1;
  //           aspect-ratio: 0.666667 / 1;
  //           display: block;
  //           position: relative;
  //           background-color: $background;
  //           &.doubleColumn {
  //             grid-column: span 2;
  //           }
  //           &.tripleColumn {
  //             grid-column: span 3;
  //           }
  //           .ratioBox {
  //             background-position: 50%;
  //             background-repeat: no-repeat;
  //             background-size: cover;
  //             width: 100%;
  //             height: 100%;
  //             position: relative;
  //           }
  //           ul {
  //             position: relative;
  //             bottom: 0px;
  //             list-style: none;
  //             display: flex;
  //             flex-wrap: wrap;
  //             &::before {
  //               padding-top: 3px;
  //               font-size: 1.5em;
  //               content: "●";
  //             }

  //             li {
  //               margin: 5px;
  //               padding-top: 5px;
  //               font-size: 1em;
  //               font-weight: 300;
  //               text-transform: uppercase;
  //               cursor: pointer;
  //               &:not(:first-child) {
  //                 &::before {
  //                   content: "/ ";
  //                 }
  //               }
  //               &:hover {
  //                 color: $noir;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  #side_menu {
    position: fixed;
    top: 0px;
    right: 0px;
    width: $margin;
    height: 100%;
    @media screen and (max-width: 800px) and (orientation: portrait) {
      width: calc($margin-mobile + 10px);
    }
    .menu_button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $background;
      z-index: 11;
      position: absolute;
      cursor: pointer;
      .icon {
        writing-mode: vertical-rl;
        text-orientation: upright;
        cursor: pointer;
        margin-top: 25px;
        font-size: 20px;
        font-weight: 400;
        @media screen and (max-width: 800px) and (orientation: portrait) {
          margin-top: 30px;
        }
      }
      .vertical_tag {
        writing-mode: vertical-lr;
        text-transform: uppercase;
        align-items: center;
        padding-bottom: 10px;
        font-size: 0.9em;
        font-weight: 400;
        display: flex;
        transform: rotate(180deg);
      }
    }

    .list {
      box-sizing: border-box;
      text-transform: uppercase;
      z-index: 10;
      background-color: $background;
      border-left: 1px solid #efefef;
      min-width: 200px;
      height: 100vh;
      padding: 20px;
      padding-top: 30px;
      font-weight: 400;
      list-style: none;
      transition: transform 0.3s ease-out;
      position: absolute;
      top: 0;
      right: $margin;
      transform: translate(100%);
      @media screen and (max-width: 800px) and (orientation: portrait) {
        padding-top: 33px;
        right: calc($margin-mobile + 10px);
      }
      li {
        cursor: pointer;
        margin-bottom: 3px;
        text-wrap: nowrap;
        &:hover {
          color: $noir;
        }
        &.separator {
          cursor: unset;
          color: unset;
          margin-bottom: 3px;
          font-size: 20px;
        }
        @media screen and (max-width: 800px) and (orientation: portrait) {
          margin-bottom: 8px;
        }
      }
    }
  }
  &.menu--open {
    #side_menu {
      .list {
        transform: translate(0%);
      }
    }
  }
}
