* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  color: #555;
  min-height: 100vh;
  margin: 0;
  padding: 20px 60px;
  font-family: Averta W01 Regular, sans-serif;
  font-weight: 100;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  body {
    padding: 0;
  }
}

body #side_menu {
  width: 60px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  body #side_menu {
    width: 35px;
  }
}

body #side_menu .menu_button {
  z-index: 11;
  cursor: pointer;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

body #side_menu .menu_button .icon {
  writing-mode: vertical-rl;
  text-orientation: upright;
  cursor: pointer;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 400;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  body #side_menu .menu_button .icon {
    margin-top: 30px;
  }
}

body #side_menu .menu_button .vertical_tag {
  writing-mode: vertical-lr;
  text-transform: uppercase;
  align-items: center;
  padding-bottom: 10px;
  font-size: .9em;
  font-weight: 400;
  display: flex;
  transform: rotate(180deg);
}

body #side_menu .list {
  box-sizing: border-box;
  text-transform: uppercase;
  z-index: 10;
  background-color: #fff;
  border-left: 1px solid #efefef;
  min-width: 200px;
  height: 100vh;
  padding: 30px 20px 20px;
  font-weight: 400;
  list-style: none;
  transition: transform .3s ease-out;
  position: absolute;
  top: 0;
  right: 60px;
  transform: translate(100%);
}

@media screen and (width <= 800px) and (orientation: portrait) {
  body #side_menu .list {
    padding-top: 33px;
    right: 35px;
  }
}

body #side_menu .list li {
  cursor: pointer;
  text-wrap: nowrap;
  margin-bottom: 3px;
}

body #side_menu .list li:hover {
  color: #000;
}

body #side_menu .list li.separator {
  cursor: unset;
  color: unset;
  margin-bottom: 3px;
  font-size: 20px;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  body #side_menu .list li {
    margin-bottom: 8px;
  }
}

body.menu--open #side_menu .list {
  transform: translate(0%);
}

@font-face {
  font-family: Averta W01 Black Italic;
  src: url("ea8c6a50-72d0-4ee6-8e0b-e47e53575d67.907b81f9.woff2") format("woff2"), url("f31b446c-483f-4030-8d67-e0d38b0b6fa4.a9b18958.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Bold Italic;
  src: url("5e5cbca9-4efe-45b6-a287-96962556b612.b79f1e5b.woff2") format("woff2"), url("d888bc61-de40-4e37-9400-0d82440a8e98.7cf53e8b.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Thin Italic;
  src: url("aa6e7c45-ff1a-4bdc-894b-46e20e759a18.438ee9c0.woff2") format("woff2"), url("8c6efa3c-b0b0-409b-83b9-7c127c7f0c13.85a469a0.woff") format("woff");
}

@font-face {
  font-family: Averta W01 ExtraBold;
  src: url("d1b10202-af0e-46d9-8037-e64031a1c01f.a0dd2f76.woff2") format("woff2"), url("10f8e758-7c4c-47ee-b3f5-6d403716df27.e82f11cf.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Black;
  src: url("4e32fe9a-3c73-4a05-b996-73380fe0d650.9fd82fb7.woff2") format("woff2"), url("355b4871-d3ef-47a0-81c2-c01f3308822c.05734ad5.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Regular;
  src: url("89f1e0ce-0337-46bd-83eb-521970fb47c9.cac5c197.woff2") format("woff2"), url("ffec1ad2-fea3-4d14-bc1f-bc1b0396417b.8966fde3.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Bold;
  src: url("c52b7ee6-2bc8-4dfc-8fef-36af3c2d0f63.1536f78e.woff2") format("woff2"), url("51d44388-ec35-4d35-a644-48812f2b3f67.0709cb94.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Thin;
  src: url("53e2bf47-af75-465c-856b-818ebb84b427.ef3eab4e.woff2") format("woff2"), url("e1c93287-d8c4-42db-b433-a1e9fe3295d1.93faf1d5.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Semibold Italic;
  src: url("f2e079f4-7f94-4e35-ac26-3fbc57d6c267.e5175b4f.woff2") format("woff2"), url("b38261c5-b66b-450d-a3ea-1fc312007f64.0291d554.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Semibold;
  src: url("1e92f6d7-7f6f-4498-8aef-b4ae8457137f.d3f789a8.woff2") format("woff2"), url("1f092190-9543-465b-803c-957470fe5c40.b12a0933.woff") format("woff");
}

@font-face {
  font-family: Averta W01 ExtraBold Italic;
  src: url("f4534f76-7894-4050-b4f9-168de09303b6.da8645d6.woff2") format("woff2"), url("b388df60-03bc-4830-a454-a9a7a42b96a7.8f73a91d.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Extrathin Italic;
  src: url("b500bff5-dcf3-43dc-ae16-600bc1b20438.ad33cf2c.woff2") format("woff2"), url("cd1b768c-7d83-4ec6-a646-6cbfbdbabbf2.d6a8c0cf.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Extrathin;
  src: url("e4532f93-feab-4dd6-877e-59b3a2ac085c.ae240b6e.woff2") format("woff2"), url("94126aee-f5cb-407e-b0b9-928300c8b27f.36feb75c.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Light;
  src: url("1072dd23-6dea-428e-bd0f-a00a69074239.af0c92cc.woff2") format("woff2"), url("53101a83-dea0-4ac0-8d40-12cf0cbe2956.45ad455f.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Light Italic;
  src: url("bd2c76e8-7ea6-4e7a-a6dd-94c9200909b4.5f3786a8.woff2") format("woff2"), url("f99fc351-1e53-4330-b268-6e870f3ce772.121c3310.woff") format("woff");
}

@font-face {
  font-family: Averta W01 Regular Italic;
  src: url("fac93f55-a52e-4691-a5f3-fc89270fe480.4556ace9.woff2") format("woff2"), url("436a67bd-97e2-4d02-83ac-07a7a7894609.8b0c0314.woff") format("woff");
}

#content {
  height: 100%;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  #content {
    width: calc(100% - 30px);
  }
}

#content #mainGallery {
  height: 100%;
}

#content #mainGallery .mainColumn {
  flex-direction: column;
  height: 100%;
  display: flex;
}

#content #mainGallery .mainColumn .horizontal_viewer {
  background-color: #0000;
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
}

#content #mainGallery .mainColumn .horizontal_viewer .handle {
  z-index: 999;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  top: -5px;
  left: -5px;
}

#content #mainGallery .mainColumn .horizontal_viewer.text {
  background-color: #0000;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  #content #mainGallery .mainColumn .horizontal_viewer.text {
    margin-bottom: 20px;
  }
}

#content #mainGallery .mainColumn .horizontal_viewer.text p {
  font-family: Averta W01 Bold Italic, sans-serif;
  font-size: 5vw;
  line-height: 5vw;
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText {
  display: flex;
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText.right {
  flex-direction: row-reverse;
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText p {
  column-gap: 10px;
  min-width: 600px;
  max-width: 50%;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Averta W01 Regular, sans-serif;
  font-size: 1.5vw;
  line-height: 1.5vw;
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText p.ql-align-right, #content #mainGallery .mainColumn .horizontal_viewer.text.regularText p span.ql-align-right {
  text-align: right;
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText p span.ql-size-large {
  font-size: 3vw;
  line-height: 3vw;
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText p span.ql-size-small {
  font-size: .9vw;
  line-height: .7vw;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  #content #mainGallery .mainColumn .horizontal_viewer.text.regularText p {
    width: calc(100% - 15px);
    min-width: calc(100% - 15px);
    max-width: calc(100% - 15px);
    font-size: 3.5vw;
    line-height: 3.5vw;
    column-count: 1 !important;
  }

  #content #mainGallery .mainColumn .horizontal_viewer.text.regularText p.ql-align-right, #content #mainGallery .mainColumn .horizontal_viewer.text.regularText p span.ql-align-right {
    text-align: unset;
  }

  #content #mainGallery .mainColumn .horizontal_viewer.text.regularText p span.ql-size-large {
    font-size: 5vw;
    line-height: 5vw;
  }

  #content #mainGallery .mainColumn .horizontal_viewer.text.regularText p span.ql-size-small {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }
}

#content #mainGallery .mainColumn .horizontal_viewer.text.regularText.fullwidth p {
  max-width: unset;
  min-width: unset;
  width: 100%;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer {
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 10px 10px;
  display: grid;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image {
  aspect-ratio: .666667;
  background-color: #0000;
  display: block;
  position: relative;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image.doubleColumn {
  grid-column: span 2;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image.tripleColumn {
  grid-column: span 3;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .ratioBox {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .ratioBox video {
  width: 100%;
  height: auto;
  display: block;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .ratioBox .download-button {
  color: #0000;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  #content #mainGallery .mainColumn .horizontal_viewer .organizer .image .ratioBox .download-button {
    display: none;
  }
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .ratioBox:hover .download-button {
  color: #fff;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .select-wrapper {
  height: 20px;
  position: relative;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .select-wrapper select {
  border: unset;
  padding: unset;
  border-radius: unset;
  color: unset;
  font-size: unset;
  background-color: #fff;
  width: 100%;
  position: absolute;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image ul {
  flex-wrap: wrap;
  list-style: none;
  display: flex;
  position: relative;
  bottom: 0;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image ul:before {
  content: "●";
  padding-top: 0;
  font-size: 1.5em;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image ul li {
  text-transform: uppercase;
  cursor: pointer;
  background-color: unset;
  color: #555;
  margin: 5px 0 5px 5px;
  padding: 5px 0 0;
  font-size: .7rem;
  font-weight: 300;
  text-decoration: underline;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image ul li:not(:first-child):before {
  content: "/ ";
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image ul li:hover {
  color: #000;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay {
  text-align: right;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .edit-button, #content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .delete-button {
  color: #555;
  cursor: pointer;
  background-color: #ffffff80;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 30px;
  right: 2px;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .edit-button:hover, #content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .delete-button:hover {
  background-color: #fff;
  box-shadow: 0 0 5px #0000001a;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .delete-button {
  left: 2px;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .delete-button:hover {
  background-color: red;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .size-button {
  cursor: pointer;
  background-color: #ffffff80;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 2px;
  display: inline-flex;
}

#content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .size-button:hover, #content #mainGallery .mainColumn .horizontal_viewer .organizer .image .overlay .size-button.selected {
  background-color: #fff;
  box-shadow: 0 0 5px #0000001a;
}

#content #mainGallery .mainColumn .horizontal_viewer .text-editor {
  padding: 10px;
}

@media screen and (width <= 800px) and (orientation: portrait) {
  #content #mainGallery .mainColumn .horizontal_viewer p {
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*# sourceMappingURL=index.b7952e44.css.map */
